.Home {
    margin-top: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-bottom: 7rem;
    overflow: hidden;
}
.image-icons{ 
    height: 70%;
    width: 70%;
}

.lis-teek {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 80px;
    width: 94%;
    margin-bottom: 20px;

}

.icon-field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 20%;
}

.titr-blog {
    height: 50px;
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* flex-direction: row-reverse; */
    border: 1px solid #e1e1e1;
    border-radius: 3px;
    background-color: #f8f8f8;
}

.right-item {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 32%;
    height: 100%;
}

.right-text {
    height: 100%;
    width: 64%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.right-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 30%;
}

.left-item {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 32%;
    height: 100%;
}

.left-text {
    height: 100%;
    width: 64%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

}

.left-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 30%;
}

a , link {
    color: black;
    text-decoration: none;
    height: 100%;
    width: 100%;
}
.linkclass{
    display: flex;
    justify-content: center;
    align-items: center;
}