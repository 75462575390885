.question-survey {
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 554px;
    width: 90%;
    background-color: transparent;
    /* border: 1px solid #d4d4d4; */
    border-radius: 10px;
    /* box-shadow: 0px 0px 17px -6px #dbdbdb; */
    margin-top: 13rem;
    margin-bottom: 8rem;
}

.head-question {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* flex-direction: column; */
    width: 100%;
    height: 13%;
    /* background-color: #cbd6ff; */
    border-radius: 4px;
}

.headque-span {
    height: 80%;
    width: 95%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    border-bottom: 1px solid lightgrey;
}

.divque-span {
    height: 100%;
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: flex-start;
    width: 87%;
    line-height: 2rem;
}

.divque-input {
    height: 35%;
    width: 7%;
    margin-left: 15px;
}

.body-question {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: 97%;
    height: 87%;
}

.bodyque-div {
    width: 95%;
    height: 50%;
    display: flex;
    justify-content: normal;
    flex-direction: row;
    align-items: baseline;
    /* padding-right: 2rem; */
    text-align: justify;
}

.bodyque-div p {
    font-size: 18px;
    line-height: 26px;
}

.bodyque-div1 {
    width: 95%;
    height: 48%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    /* padding-right: 2rem; */
}

.bodyque-div1 span {
    width: 100%;
    height: 23%;
    text-align: justify;
    font-size: 14px;
}

.bodyque-div1 svg {
    margin-left: 8px;
}

.btn-question {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* flex-direction: column; */
    width: 80%;
    height: 13%;
    padding: 0rem 1rem;
}

.btnque-button {
    height: 40px;
    width: 190px;
    border: 0px;
    outline: 0px;
    background: #131428;
    border-radius: 4px;
    font-size: 22px;
    color: white;
}

.btnque-button:hover {
    background-color: #1e1f4b;
}

.btnque-button:active {
    background-color: #181934;
}