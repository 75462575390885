.App {
  text-align: center;
  display: flex;
  align-items: center;
  width: 100%;
  direction: rtl;
  flex-direction: column;
  background-color: #ffffff;
  max-width: 550px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

/* body {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: row-reverse;
} */