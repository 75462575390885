.change-main {
    border: 1px solid #e0e0e0;
    height: 625px !important;
}

.input-main {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: flex-start;
    height: 21%;
    width: 95%;

}

.others-input {
    height: 35%;
    width: 87%;
    border: 1px solid #d5d5d5;
    border-radius: 7px;
    padding: 0.5rem 1rem;
}

.others-input:focus {
    border: 1px solid red;
    background-color: yellowgreen;
}

.others-input:active {
    border: 1px solid blue;
}

.textarea-main {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: flex-start;
    height: 32%;
    width: 95%;

}

.others-textarea {
    height: 63%;
    width: 87%;
    border: 1px solid #d5d5d5;
    border-radius: 7px;
    padding: 0.5rem 1rem;

}

.label-main {
    font-size: 18px;
    color: black;
}