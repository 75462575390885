.firstotp-image {
    height: 23%;
    width: 100%;
}

.change-first {
    border: 1px solid rgb(230, 230, 230);
    height: 525px;
}

.image-mashinno {
    height: auto;
    width: 75%;
}

.input-secondotp {}

.input-firstotp {
    height: 30%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    padding-right: 10px;
}
.input-firstotp1 {
    height: 30%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    padding-right: 10px;
}