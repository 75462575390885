.bor-buttom {
    border-bottom: 3px solid black;
}


.footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 110px;
    width: 84%;
    position: fixed;
    bottom: 1%;
    background-color: white;
    left: 8%;
    border-radius: 7px;
    z-index: 1000;
    box-shadow: 0px 0px 10px -5px rgb(0, 0, 0);
}

.menuitem-img {
    height: auto;
    width: 100%;
}

.icons {
    margin-left: 5px;
}

.img-list {
    width: 25%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}


.menu-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    background-color: white;
    width: 100%;
    height: 60px;
    top: 0;
    left: 0;
    z-index: 1000;
    padding: 1rem 0;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: white;
    width: 100%;
    height: 80px;
    top: 80px;
    left: 0;
    z-index: 1000;
    box-shadow: 0px 4px 4px -5px #d1cccc;

}

.header-div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 49.43%;
    height: 100%;
}

.icon-list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 90%;
    width: 25%;
    margin-left: 10px;
}



.dis-mid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.jus-event {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wid-96 {
    width: 96%;
}

.wid-92 {
    width: 92%;
}

.bodyicon {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}





.banner {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    height: 200px;
    width: 90%;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
}

.banner-head {
    width: 100%;
    height: 14%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.banner-text {}

.banner-khat {
    height: 2px;
    width: 48%;
    background-color: rgb(4, 4, 4);
}

.banner-body {
    width: 100%;
    height: 83%;
    border-radius: 10px;
    background: red;
}

.slider-image {
    height: 95%;
    width: 100%;
    border-radius: 10px;
}

.slider-container {
    /* z-index: 100; */
    border-radius: 10px;
}


.text-class {
    font-size: 12px;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.under-foot1 {

    height: 95%;
    width: 22%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.under-foot2 {
    height: 80%;
}

@media only screen and (min-width : 800px) {

    .footer {
       width: 550px;
        left: 0;
        right: 0;
        margin: auto;
        height: 100px;
    }
    .header{
        width: 728px;
        left: 0;
        right: 0;
        margin: auto;
    }
    .menu-item {
       width: 728px;
        left: 0;
        right: 0;
        margin: auto;
    }

}